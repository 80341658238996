<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                Register New User
            </h3>
            <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                <i class="mdi mdi-keyboard-backspace"></i> Back</button>

            <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
            <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
            <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
          <li class="breadcrumb-item active" aria-current="page">Basic</li>
        </ol>
      </nav> -->
        </div>
        <div class="row">
            <div class="col-md-8 m-auto d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">


                                <b-form @submit.stop.prevent="onSubmit">
                                    <div class="container-fluid my-4">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <!-- <div class="file-box" style="padding:10px 20px; cursor: default;"> -->
                                                <b-form-group id="example-input-group-1" label="Name"
                                                    label-for="example-input-1">
                                                    <b-form-input id="example-input-1" placeholder="Enter Username"
                                                        name="example-input-1" v-model="$v.form.name.$model"
                                                        :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                        aria-describedby="input-1-live-feedback"></b-form-input>

                                                    <div v-if="$v.form.name.$error" class="invalid-feedback">
                                                        <div v-if="!$v.form.name.required">Username is required</div>
                                                        <div v-if="!$v.form.name.maxLength">Maximum limit 100
                                                            characters</div>
                                                        <div v-if="!$v.form.name.alpha">Type alphabets only</div>
                                                    </div>

                                                </b-form-group>



                                                <!-- </div> -->
                                            </div>
                                            <div class="col-md-12">
                                                <!-- <div class="file-box" style="padding:10px 20px; cursor: default;"> -->
                                                <b-form-group id="example-input-group-1" label="Email"
                                                    label-for="example-input-1">
                                                    <b-form-input type="email" id="example-input-1"
                                                        placeholder="Enter Email" name="example-input-1"
                                                        v-model="$v.form.email.$model"
                                                        :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
                                                        aria-describedby="input-1-live-feedback"></b-form-input>
                                                    <div v-if="$v.form.email.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.email.required">Email is required</span>
                                                        <span v-if="!$v.form.email.email">Enter valid email</span>
                                                        <div v-if="!$v.form.email.maxLength">Maximum limit 100
                                                            characters</div>
                                                    </div>
                                                </b-form-group>


                                                <!-- </div> -->
                                            </div>



                                            <div class="col-md-12">
                                                <!-- <div class="file-box" style="padding:10px 20px; cursor: default;"> -->
                                                <b-form-group id="example-input-group-1" label="Password"
                                                    label-for="example-input-1">
                                                    <b-form-input type="text" id="example-input-1"
                                                        placeholder="Enter Password" name="example-input-1"
                                                        v-model="$v.form.password.$model"
                                                        :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                                                        aria-describedby="input-1-live-feedback"></b-form-input>

                                                    <div v-if="$v.form.password.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.password.required">Password is required</span>
                                                        <span v-if="!$v.form.password.minLength">Password must be at least 6
                                                            characters</span>
                                                    </div>


                                                </b-form-group>



                                                <!-- <p class="err" v-if="pass_err != ''">{{ pass_err }}</p> -->
                                                <!-- </div> -->
                                            </div>

                                            <div class="col-md-12">
                                                <!-- <div class="file-box" style="padding:10px 20px; cursor: default;"> -->
                                                <b-form-group id="example-input-group-1" label="Confirm Password"
                                                    label-for="example-input-1">
                                                    <b-form-input type="text" id="example-input-1"
                                                        placeholder="Re enter password for confirmation"
                                                        name="example-input-1" v-model="$v.form.password_confirm.$model"
                                                        :state="$v.form.password_confirm.$dirty ?
                                                            !$v.form.password_confirm.$error : null"
                                                        aria-describedby="input-1-live-feedback"></b-form-input>

                                                    <div v-if="$v.form.password_confirm.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.password_confirm.required">Password
                                                            confirmation is required</span> ,
                                                        <span v-if="!$v.form.password_confirm.sameAs">Confirm password not
                                                            match with password</span>
                                                    </div>
                                                </b-form-group>



                                                <!-- <p class="err" v-if="pass_confirm_err != ''">{{ pass_confirm_err }}</p> -->
                                                <!-- </div> -->
                                            </div>



                                            <!-- <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="exampleInput">Password</label>
                                                    <div class="input-group">
                                                        <input :type="passwordFieldType" class="form-control"
                                                            v-model="$v.form.password.$model" 
                                                            :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                                                            id="inlineFormInputGroup1"
                                                            placeholder="Password">
                                                        <div class="input-group-prepend" style="cursor:pointer"
                                                            @click="showPassword()">
                                                            <div class="input-group-text"><i :class="eyeIcon"></i></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="err" v-if="pass_err != ''">{{ pass_err }}</p>
                                            </div> -->


                                            <!-- <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Confirm Password</label>
                                                    <div class="input-group">
                                                        <input :type="passwordFieldType_confirm" class="form-control"
                                                            v-model="$v.form.password_confirm.$model" 
                                                            :state="$v.form.password_confirm.$dirty ? !$v.form.password_confirm.$error : null"
                                                            id="inlineFormInputGroup2"
                                                            placeholder="Enter Password for confirmation">
                                                        <div class="input-group-prepend" style="cursor:pointer"
                                                            @click="showConfirmPassword()">
                                                            <div class="input-group-text"><i
                                                                    :class="eyeIcon_confirm"></i></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="err" v-if="pass_confirm_err != ''">{{ pass_confirm_err }}</p>
                                            </div> -->


                                            <div class="col-md-12">
                                                <b-form-group id="input-group-3" label="Type" label-for="input-3">
                                                    <b-form-select id="input-3" v-model="$v.form.type.$model"
                                                        :state="$v.form.type.$dirty ? !$v.form.type.$error : null"
                                                        :options="types"></b-form-select>

                                                    <div v-if="$v.form.type.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.type.required">Type is required</span>
                                                    </div>
                                                </b-form-group>



                                            </div>


                                        </div>

                                        <div class="text-right mt-3">
                                            <b-button :disabled='is_loading' type="submit" variant="success"
                                                class="btn believe-btn btn-block">
                                                <i
                                                    :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                                Submit
                                            </b-button>
                                        </div>
                                    </div>
                                </b-form>


                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength, sameAs, helpers } from 'vuelidate/lib/validators';
const alpha = helpers.regex('alpha', /^[a-zA-Z\s]*$/);
// /^[a-zA-Z]*$/
import api from "../../../config/api.js";
import Tag from "@/components/Tag.vue";
import Goal from "@/components/Goal.vue";
import Feeling from "@/components/Feeling.vue";
import AudioFile from "@/components/AudioFile.vue";
import { VueEditor } from "../../../../node_modules/vue2-editor";
// import firebase from 'firebase';
// import "firebase/compat/auth";
// import { getAuth } from 'firebase/auth'
// import { auth } from '../firebase/config'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioAddModal',
    mixins: [validationMixin],
    data() {
        return {
            msg: "",
            is_loading: false,
            image_error: "",
            image_url: "",
            passwordFieldType: 'password',
            passwordFieldType_confirm: 'password',

            eyeIcon: 'fa fa-eye',
            eyeIcon_confirm: 'fa fa-eye',
            eyeIcon_old: 'fa fa-eye',
            pass_confirm_err: null,
            pass_old_err: null,
            pass_err: null,
            types: [{ text: 'Choose Type...', value: null, selected: true, disabled: true, },
            { text: 'Admin', value: 'admin' }, { text: 'User', value: 'user' }],
            form: {
                type: null,
                id: null,
                name: null,
                image: "",
                email: null,
                password: null,
                password_confirm: null,
            }
        }
    },

    components: {
        VueEditor,
        Tag,
        AudioFile, Goal,
        Feeling,
        // simpleSnotifport { helpers } from 'vuelidate/lib/validators'
    },
    validations: {
        form: {
            name: {
                required,
                maxLength: maxLength(100),
                alpha
            },
            email: {
                email,
                required,
                maxLength: maxLength(100)
            },
            password: {
                required,
                minLength: minLength(6)
            },
            password_confirm: {
                required,
                sameAsPassword: sameAs('password')
            },
            type: {
                required,
            },
        }
    },
    watch: {
        tags(newVal) {
            if (newVal.length > 0) {
                this.tags_err = "";
            }
        }
        , goals(newVal) {
            if (newVal.length > 0) {
                this.goal_err = "";
            }
        },
        feelings(newVal) {
            if (newVal.length > 0) {
                this.feeling_err = "";
            }
        }
    },

    mounted() {
        window.addEventListener("keypress", function (e) {
            if (e.code == "Enter") {
                e.preventDefault()
            }
        }.bind(this));
        this.items();
    },

    methods: {

        updateTagFromChild(arr) {
            this.tags = arr;
        },

        updateGoalFromChild(arr) {
            this.goals = arr;
        },
        updateFeelingFromChild(arr) {
            this.feelings = arr;
        },

        async items() {
            let data = await this.$store.getters['getEditFormData'];
            if (data.length == 0) {
                data = localStorage.getItem('data_for_edit');
                data = JSON.parse(data);
            }
            this.category_name = data?.category_name
        },


        closeAudioPlayer() {
            this.audioFile = "";
            this.$refs.audioFile.value = null;
        },

        goBack() {
            // const category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
            // let prev_url = `/users/list`;
            let prev_url = this.$store.state.prev_url;
            if (prev_url == null || prev_url == "") {
                prev_url = localStorage.getItem('prev_url');
            }
            this.$router.push({ path: prev_url });
        },

        updateTagFromChild(arr) {
            this.tags = arr;
        },
        onAudioImageChange(e) {
            this.form.image = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image1')
            if (status) {
                this.image_error = "";
                this.form.image = file;
                this.image_url = URL.createObjectURL(file)
            }
        },
        onAudioImageChange2(e) {
            this.form.cover_image = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image2')
            if (status) {
                this.image_error2 = "";
                this.form.cover_image = file;
                this.image_url2 = URL.createObjectURL(file)
            }
        },

        checkFileType(file, num) {
            var types = ['image/jpeg', 'image/png', 'image/jpg']
            var msg = "The image must be a file of type: jpeg, jpg, png";
            if (num == 'audio') {
                types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
                if (!types.includes(file.type)) {
                    this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
                    this.audioFile = ""
                    return false;
                }
                return true;
            } else {
                if (!types.includes(file.type)) {
                    if (num == 'image1') {
                        this.image_error = msg
                        this.form.image = "";
                    }
                    if (num == 'image2') {
                        this.image_error2 = msg
                        this.form.cover_image = "";
                    }
                    return false;
                }
                return true;
            }
        },

        uploadAudio(e) {
            this.audioFile_err = "";
            this.audioFile = "";
            const audio = e.target.files[0];
            var status = this.checkFileType(audio, 'audio');
            if (status) {
                const reader = new FileReader();
                reader.readAsDataURL(audio);
                var audio_el = document.createElement("audio");
                reader.onload = (e) => {
                    this.audioFile = e.target.result;
                    console.log('audio upload');
                    console.log(this.audioFile);
                    audio_el.src = e.target.result;
                    audio_el.addEventListener("loadedmetadata", () => {
                        var duration = audio_el.duration;
                        // const audio_file = new Date(duration * 1000).toISOString().slice(14, 19);
                        this.file_duration = duration;
                        // console.log(
                        //   "The duration of the song is of: " + this.file_duration + " seconds"
                        // );
                    });
                };
                this.count++;
                // console.log(this.file_duration, "Song Duration");
            }
            console.log('audio upload');
            console.log(this.audioFile);
        },

        async onSubmit() {
            this.pass_confirm_err = null;
            this.pass_err = null;


            // console.log(this.$v.form.description.$error);
            // console.log(this.$v.form.author.$error);
            this.$v.form.$touch()

            if (this.$v.form.$anyError) {
                return false;
            }

            // console.log(this.form.password.length);

            // if (this.form.password.length < 6) {
            //     this.pass_err = "Password should have 6 characters minimum"
            //     return false;
            // }
            // if (this.form.password_confirm != this.form.password) {
            //     this.pass_confirm_err = "Password not match"
            //     return false;
            // }


            // if (this.image_url != ""
            //     // && this.image_url2 != ""
            //     && this.form.text != null
            //     && this.tags.length != 0
            //     && this.goals.length != 0
            //     && this.feelings.length != 0
            // ) {
            try {
                // const response = await this.firebase_auth.auth().createUserWithEmailAndPassword(this.form?.email, this.form?.password);
                // console.log(response);
                this.is_loading = true;

                const auth = getAuth();
                const firebase_response = await createUserWithEmailAndPassword(
                    auth,
                    this.form?.email,
                    this.form?.password
                );
                const backend_response = await api.post("/firebase", {
                    token: firebase_response?.user?.accessToken,
                    name: this.form?.name,
                    type: this.form?.type,
                    password: this.form?.password
                });

                console.log(backend_response);
                // let obj = this.$store.getters['getEditFormData'];
                // let endpoint = "/admin/register-user"
                // /admin/register-user
                // const formData = new FormData();
                // formData.append("name", this.form?.name);
                // formData.append("email", this.form?.email);
                // formData.append("password", this.form?.password);
                // formData.append("confirm_password", this.form?.password_confirm);
                // let result = await api.post(endpoint, formData);
                if (backend_response.status == 200) {
                    // const msg = await result.data.message;
                    const msg = "User Registered Successfully";
                    this.$store.dispatch('setMessage', { msg });

                    let prev_url = this.$store.state.prev_url;
                    if (prev_url == null || prev_url == "") {
                        prev_url = localStorage.getItem('prev_url');
                    }
                    this.$router.push({ path: prev_url });

                    this.is_loading = false;
                }

                // this.$store.dispatch('setMessage', { msg });
                // console.log(this.$root.$refs);
                // await this.$store.dispatch(`scripts/all_after_submit`, {
                //     id: type[type.length - 1]
                // });
                // const category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

            } catch (error) {
                console.log(error?.message);
                this.$swal('Something went wrong!', error?.message, 'error')
                this.is_loading = false;
            }
        },


        // showSnotifySuccess(msg) {
        //     let obj = this.$store.getters['getMessage'];
        //     this.$snotify.success(obj.msg, {
        //         timeout: 5000,
        //         showProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //     });
        // },
        // showSnotifyError(msg) {
        //     let obj = this.$store.getters['getMessage'];
        //     this.$snotify.error(obj.msg, {
        //         timeout: 5000,
        //         showProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //     });
        // },

    },




}
</script>

<style>
.ql-editor {
    /* background-color: #909090; */
    /* background-color: #166d8c; */
}</style>